// create an empty component

import Close from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { JestTestId } from "src/constants/tests";
import { useMaintenanceWindow } from "src/hooks/use-maintenance-window";
import { usePendingInvites } from "src/hooks/use-pending-invites";

import { NotificationsStyles } from "./notifications.styles";
import { NotificationsInviteListItem } from "./notifications-invite-list-item";
import { NotificationMaintenanceListItem } from "./notifications-maintenance-list-item.component";
import { NotificationsPendingInvitesHeader } from "./notifications-pending-invites-header";

interface NotificationsPendingInvitesProps {
    onClose: () => void;
}

export const NotificationsPendingInvites: React.FC<NotificationsPendingInvitesProps> = (props) => {
    const { pendingInvites, onAccept, onAcceptAll, loading } = usePendingInvites();
    const { message } = useMaintenanceWindow();

    const doesNotHaveAnyNotifications = pendingInvites.length === 0 && !message;
    return (<NotificationsStyles.Card>
        <NotificationsStyles.CardHeader
            titleTypographyProps={{
                variant: 'body1',
                sx: {
                    fontSize: '14px'
                }
            }}
            title='Notifications'
            action={<IconButton
                data-testid={JestTestId.NotificationsPendingInvitesCloseButton}
                onClick={props.onClose}>
                <Close />
            </IconButton>}
        />
        <Divider />
        <NotificationsStyles.CardContent>
            {pendingInvites.length > 0 && <>
                <NotificationsPendingInvitesHeader
                    loading={loading}
                    onAcceptAll={onAcceptAll}
                    pendingInvites={pendingInvites} />
                {pendingInvites.map((invite) => <NotificationsInviteListItem
                    loading={loading}
                    onAccept={onAccept}
                    key={invite.email} invite={invite} />)}
            </>}
            {<NotificationMaintenanceListItem key={"maintenance"} />}

            {(doesNotHaveAnyNotifications) && <NotificationsStyles.EmptyNotifications>No new
                notifications</NotificationsStyles.EmptyNotifications>}
        </NotificationsStyles.CardContent>
    </NotificationsStyles.Card>
    );
};