import React from "react";

interface NotificationsHookState {
    isOpen: boolean;
    onOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClose: () => void;
    anchorEl: HTMLButtonElement | null;
}

export const useNotifications = (): NotificationsHookState => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return {
        isOpen: Boolean(anchorEl),
        onOpen: handleTriggerClick,
        onClose: handleClose,
        anchorEl
    }
}