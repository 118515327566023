import LoadingButton from "@mui/lab/LoadingButton";
import { LinkActionInviteToLoanResponseDto } from "src/backend";

import { NotificationsPendingInvitesHeaderStyles } from "./notifications-pending-invites-header.styles";

interface NotificationsPendingInvitesHeaderProps {
    pendingInvites: LinkActionInviteToLoanResponseDto[];
    onAcceptAll: () => void;
    loading: boolean;
}

export const NotificationsPendingInvitesHeader = (props: NotificationsPendingInvitesHeaderProps) => {
    return <NotificationsPendingInvitesHeaderStyles.Container>
        You have {props.pendingInvites.length} new  {props.pendingInvites.length === 1 ? 'invite' : 'invites'}
        <div>
            <LoadingButton
                loading={props.loading}
                size='small'
                onClick={props.onAcceptAll}>
                Accept all
            </LoadingButton>
        </div>
    </NotificationsPendingInvitesHeaderStyles.Container>
}