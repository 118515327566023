import * as React from "react"

export const Bell = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.442 17.501a1.666 1.666 0 0 1-2.884 0M15 7.001c0-1.414-.527-2.77-1.464-3.77-.938-1-2.211-1.563-3.536-1.563-1.326 0-2.598.562-3.536 1.562C5.527 4.23 5 5.587 5 7.001c0 6.223-2.5 8-2.5 8h15s-2.5-1.777-2.5-8Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
