import Breadcrumb from 'components/dist/atoms/Breadcrumb';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { LOAN_TABS, LoanSidebar, Route } from 'src/constants/ui';
import { useUser } from 'src/hooks/use-user';
import { useGetLoanByIdQuery } from 'src/services/loanApi';
import { uiSelector } from 'src/slices/ui';
import { useSelector } from 'src/store';

import { NextLinkComposed } from '../common/next-link-composed';


export const DashboardNavbarBreadcrumbs = () => {
    const router = useRouter();
    // TODO use url params to determine the current open loan task
    const { openSidebar } = useSelector(uiSelector);
    const { isLender } = useUser();
    const loanData = useGetLoanByIdQuery(String(router.query.loanId), {
        skip: !router.query.loanId
    });
    const BreadcrumbItems: {
        label: string;
        pathname: string;
        query: Record<string, string>;
    }[] = [];
    if (([Route.HOME, Route.SINGLE_LOAN, Route.SINGLE_LOAN_MESSAGES, Route.LOANS_PHASE_CATEGORY] as string[]).includes(router.pathname) || router.pathname.includes('/loans')) {
        BreadcrumbItems.push({
            label: 'Loans',
            pathname: Route.HOME,
            query: {}
        });
    }
    // if we have a phase query param, add the phase breadcrumb
    if (router.query.phase) {
        // if phase is LEAD, add the lead breadcrumb with Leads label
        if (router.query.phase === 'LEAD') {
            BreadcrumbItems.push({
                label: 'Leads',
                pathname: Route.HOME,
                query: {
                    phase: 'LEAD'
                }
            });
            // for other phases , add the phase breadcrumb with the phase label
        } else {
            const phase = String(router.query.phase).toLowerCase();
            BreadcrumbItems.push({
                label: phase,
                pathname: Route.LOANS_PHASE_CATEGORY,
                query: {
                    phase: phase
                }
            });
        }
    }
    // if we don't have a phase and we don't have a loan, 
    // and user is lender
    // and we are on homepage or loan page
    // return Origination as the default
    if (!router.query.phase &&
        !router.query.loanId &&
        isLender &&
        (router.pathname === Route.HOME || router.pathname.includes('/loans'))) {
        BreadcrumbItems.push({
            label: 'Origination',
            pathname: Route.HOME,
            query: {
                phase: 'ORIGINATION'
            }
        });
    }
    // if we have loan data, add the loan breadcrumbs
    if (loanData.currentData) {
        let openTab = 'Overview'
        if (router.query.tab === LOAN_TABS.PACKAGE) {
            openTab = 'Package';
        }
        if (router.pathname === Route.SINGLE_LOAN_MESSAGES) {
            openTab = 'Messages';
        } else if (openSidebar === LoanSidebar.TASKS_LIST_SHOE_BOX) {
            openTab = 'Shoebox';
        } else if (([Route.SINGLE_LOAN_TASKS, Route.SINGLE_LOAN_TASKS_TYPE] as string[]).includes(router.pathname)) {
            openTab = 'Tasks';
        }
        if (isLender) {
            BreadcrumbItems.push({
                label: loanData.currentData.loanPhase.category.toLowerCase(),
                pathname: Route.LOANS_PHASE_CATEGORY,
                query: {
                    phase: loanData.currentData.loanPhase.category
                }
            })
        }
        BreadcrumbItems.push({
            label: `${loanData.currentData.shortCode} : ${loanData.currentData.projectName} ${openTab}`,
            pathname: Route.SINGLE_LOAN,
            query: {
                loanId: loanData.currentData.id
            }
        });
    }

    if (BreadcrumbItems.length === 0) {
        return null;
    }

    return <div className='overflow-hidden' >
        <Breadcrumb>
            <Breadcrumb.List>
                {BreadcrumbItems.map((item) => {
                    const isLast = BreadcrumbItems[BreadcrumbItems.length - 1] === item;
                    return <Fragment key={`${item.pathname}-${item.label}`}>
                        <Breadcrumb.Item className={`${isLast ? 'text-foreground' : 'shrink-0'} capitalize`}>
                            {!isLast && <Breadcrumb.Link asChild>
                                <NextLinkComposed to={{
                                    pathname: item.pathname,
                                    query: item.query
                                }}>
                                    <span className='truncate'>{item.label}</span>
                                </NextLinkComposed>
                            </Breadcrumb.Link>}
                            {isLast && <span className='truncate'>{item.label}</span>}
                        </Breadcrumb.Item>
                        {!isLast && (<Breadcrumb.Separator />)}
                    </Fragment>
                }
                )}
            </Breadcrumb.List>
        </Breadcrumb>
    </div>
}