
import Icon from "components/dist/atoms/Icon";

import { useMaintenanceWindow } from "../../../hooks/use-maintenance-window";
import { NotificationMaintenanceListItemStyles } from "./notifications-maintenance-list-item.styles";

export const NotificationMaintenanceListItem = () => {
    const { title, message } = useMaintenanceWindow();

    if (message) {
        return <NotificationMaintenanceListItemStyles.Wrapper>
            <NotificationMaintenanceListItemStyles.IconWrapper>
                <Icon name="FolderSettings" width={20} height={20} />
            </NotificationMaintenanceListItemStyles.IconWrapper>
            <NotificationMaintenanceListItemStyles.Row>
                <NotificationMaintenanceListItemStyles.Title>
                    {title}
                </NotificationMaintenanceListItemStyles.Title>
                <NotificationMaintenanceListItemStyles.Content
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </NotificationMaintenanceListItemStyles.Row>
        </NotificationMaintenanceListItemStyles.Wrapper>
    } else {
        return null;
    }
}