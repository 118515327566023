import { add, format, parseISO } from "date-fns";
import { useEffectAtMount } from "polyrhythm-react";
import { useMemo } from "react";
import {useSubscription} from "react-stomp-hooks";

import { MaintenanceWindowDto } from "../backend";
import { clearMaintenanceWindow, fetchMaintenanceWindow, setMaintenanceWindow } from "../slices/system";
import { useDispatch, useSelector } from "../store";

const EmptyMessage = {
    title: null,
    message: null
}

export function useMaintenanceWindow() {
    const fetchedWindow: MaintenanceWindowDto = useSelector(state => state.system.maintenanceWindow);
    const dispatch = useDispatch();

    useSubscription('/topic/admin/maintenance', () => {
        dispatch(fetchMaintenanceWindow());
    });

    const setWindow = async (start: string, end: string) => {
        await dispatch(setMaintenanceWindow(start, end));
    }

    const clearWindow = async () => {
        await dispatch(clearMaintenanceWindow());
    }

    useEffectAtMount(() => {
        dispatch(fetchMaintenanceWindow());
    })

    const {
        title,
        message,
    } = useMemo(() => {
        if (!fetchedWindow) return EmptyMessage;

        if (fetchedWindow.start && fetchedWindow.end) {
            const now = new Date();
            const twentyFourHoursFromNow = add(now, { hours: 24 });
            const start = parseISO(fetchedWindow.start);
            const end = parseISO(fetchedWindow.end);
            const startIsInLessThan24Hours = start < twentyFourHoursFromNow;
            const startEndIsSameDay = format(start, 'MMM d, yyyy') === format(end, 'MMM d, yyyy');
            const title = startIsInLessThan24Hours
                ? "Scheduled maintenance within 24 hours."
                : "Scheduled maintenance.";
            const message = startIsInLessThan24Hours && startEndIsSameDay ?
                `App maintenance is scheduled to start on <strong>${format(start, 'MMM d, yyyy')}</strong> from <strong>${format(start, 'h:mm a')}</strong> to <strong>${format(end, 'h:mm a')}.</strong> The application may be unavailable during that time.` :
                `App maintenance is scheduled from <strong>${format(start, 'MMM d, yyyy h:mm a')}</strong> to <strong>${format(end, 'MMM d, yyyy h:mm a')}</strong>. The application may be unavailable during that time.`
            return {
                title,
                message
            }
        }

        return EmptyMessage;
    }, [fetchedWindow])

    return {
        title,
        message,
        fetchedWindow,
        setWindow,
        clearWindow
    }

}