import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import * as React from 'react';
import { KeyboardKey } from 'src/constants/ui';
import { AuthContext } from 'src/contexts/auth-context';
import useEventListener from 'src/hooks/use-event-listener';
import { useFetchUserPreferences } from 'src/hooks/use-fetch-user-prefrences';
import { getAssetPath } from 'src/utils/url/get-asset-path';

import { SplashScreen } from './splash-screen';

type Props = {
  children?: JSX.Element;
}

export const PrivacyCookieConsent: React.FC<Props> = (props) => {
  const iframeReference = React.useRef<HTMLIFrameElement>();
  const contentDocumentReference = React.useRef<Document>();
  const [isAgreeEnabled, setAgreeEnable] = React.useState(false);
  const { loading, agreedToTOS, onUpdate } = useFetchUserPreferences();

  const handleAgree = () => {
    if (isAgreeEnabled) {
      onUpdate({ agreedToTOS: true });
    } else if (contentDocumentReference.current) {
      // get height of contentDocumentReference.current
      const height = contentDocumentReference.current.body.clientHeight;
      contentDocumentReference.current.body.scrollTop = contentDocumentReference.current.body.scrollTop + height;
    }
  };

  // detect user scroll all the way to bottom 
  const handleScroll = (event) => {
    const scrollTop = event.target.activeElement.scrollTop;
    const scrollHeight = event.target.activeElement.scrollHeight;
    const clientHeight = event.target.activeElement.clientHeight;
    // when browser is zoomed there is a 0~1px difference between scrollHeight and clientHeight + scrollTop
    // So to be safe we are adding 5px to the clientHeight + scrollTop and rounding to an integer
    if ((Math.round(scrollTop + clientHeight) + 5) >= scrollHeight) {
      setAgreeEnable(true);
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KeyboardKey.ENTER && !agreedToTOS) {
      event.stopPropagation();
      handleAgree();
    }
  }

  const handleLoad = (event) => {
    contentDocumentReference.current = event.target.contentDocument;
    contentDocumentReference.current?.addEventListener('scroll', handleScroll);
    contentDocumentReference.current?.addEventListener('keydown', handleKeyDown);
  }

  useEventListener('keydown', handleKeyDown);

  React.useEffect(() => {
    if (agreedToTOS) {
      return;
    }
    const ref = iframeReference.current;
    if (ref) {
      return () => {
        ref?.removeEventListener('load', handleLoad);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreedToTOS]);

  React.useEffect(() => {
    contentDocumentReference.current?.addEventListener('scroll', handleScroll);
    contentDocumentReference.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      contentDocumentReference.current?.removeEventListener('scroll', handleScroll);
      contentDocumentReference.current?.removeEventListener('keydown', handleKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgreeEnabled, contentDocumentReference.current]);


  if (loading) {
    return <SplashScreen />
  }

  if (agreedToTOS) {
    return props.children;
  }

  return (<Dialog open={!agreedToTOS}>
    <Dialog.Content
      className='sm:max-w-7xl	h-full' aria-labelledby="scroll-dialog-title">
      <Text weight="medium" className="px-3 pt-3" id="scroll-dialog-title">Platform License Agreement and Terms of Service</Text>
      <Separator />
      <div className='overflow-hidden flex-1'>
        <iframe
          ref={(node: HTMLIFrameElement) => {
            if (!node) return;
            iframeReference.current = node;
            node.addEventListener('load', handleLoad);
          }}
          title="TOS and PLA"
          className="border-0 px-4 w-full h-full lg:min-h-[800px] overflow-auto scrollbar-stable"
          src={getAssetPath('static/pla.html')} />
      </div>
      <Separator />
      <Stack row space="sm" justify='end' className='px-3 pb-3'>
        {!isAgreeEnabled && <Text
          className="flex-1"
          size="sm"
          weight="medium">
          Read all the way to the bottom of this page to agree to our Platform License Agreement and Terms of Service.
        </Text>}
        <Button
          className='min-w-20'
          data-testid='agree-button'
          onClick={handleAgree}>
          {isAgreeEnabled ? 'I Agree' : 'Next Page...'}
        </Button>
      </Stack>
    </Dialog.Content>
  </Dialog>
  );
}
