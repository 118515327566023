import React from "react";
import { api } from "src/api";
import { LinkActionInviteToLoanResponseDto } from "src/backend";
import { getPendingInvites, selectPendingInvites } from "src/slices/user";
import { useDispatch, useSelector } from "src/store";
import { toast } from "src/utils/toast";

import { useLoans } from "./use-loans";

interface PendingInvitesHookState {
    pendingInvites: LinkActionInviteToLoanResponseDto[];
    refresh: () => void;
    onAccept: (invite: LinkActionInviteToLoanResponseDto) => void;
    onAcceptAll: () => void;
    loading: boolean;
}

export const usePendingInvites = (): PendingInvitesHookState => {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const pendingInvites = useSelector(selectPendingInvites);
    const { refreshLoans } = useLoans()
    React.useEffect(() => {
        dispatch(getPendingInvites())
    }, [dispatch]);

    const handleRefreshPendingInvites = React.useCallback(() => {
        dispatch(getPendingInvites())
    }, [dispatch]);

    const handleAcceptInvite = React.useCallback(async (invite: LinkActionInviteToLoanResponseDto) => {
        await api.doAction(invite.id);
        toast({
            content: "Invite accepted",
            type: "success"
        })
        refreshLoans();
        // refresh pending invites
        handleRefreshPendingInvites();
    }, [handleRefreshPendingInvites, refreshLoans]);

    const handleAcceptAll = React.useCallback(async () => {
        setLoading(true);
        await Promise.all(pendingInvites.map(invite => api.doAction(invite.id)));
        // refresh pending invites
        handleRefreshPendingInvites();
        refreshLoans();
        setLoading(false);
        toast({
            content: "All invites accepted",
            type: "success"
        })
    }, [handleRefreshPendingInvites, pendingInvites, refreshLoans]);

    return {
        pendingInvites,
        loading,
        onAcceptAll: handleAcceptAll,
        onAccept: handleAcceptInvite,
        refresh: handleRefreshPendingInvites
    }
}