import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Icon from 'components/dist/atoms/Icon';
import React from "react";
import { usePendingInvites } from "src/hooks/use-pending-invites";

import { useMaintenanceWindow } from "../../../hooks/use-maintenance-window";
import { useNotifications } from "./notifications.hook";
import { NotificationsPendingInvites } from "./notifications-pending-invites";

interface NotificationsButtonProps {
}

export const Notifications: React.FC<NotificationsButtonProps> = () => {

    const { message } = useMaintenanceWindow();
    const {
        onOpen,
        onClose,
        isOpen,
        anchorEl
    } = useNotifications()

    const { pendingInvites } = usePendingInvites();
    const hasNotifications = Boolean(pendingInvites.length) || !!message ? 1 : 0;

    return (
        <>
            <IconButton
                onClick={onOpen}
                color='default'>
                <Badge
                    overlap="circular"
                    color='error'
                    variant="dot"
                    badgeContent={hasNotifications}>
                    <Icon name="Bell" className='text-black-primary' width={20} height={20} strokeWidth={2} />
                </Badge>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                disableScrollLock
                onClose={onClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <NotificationsPendingInvites
                    onClose={onClose} />
            </Popover>
        </>
    );
};