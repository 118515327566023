import React from "react";
import { Permission } from "src/constants/user";
import { useUser } from "src/hooks/use-user";
import { roleCan } from "src/utils/user/role-can";

interface RoleGuardProps {
    permission: Permission;
}

export const UserRoleGuard = (props: React.PropsWithChildren<RoleGuardProps>) => {
    const { userRole } = useUser();

    if (!userRole || !roleCan(userRole, props.permission)) {
        return null;
    }

    return <>{props.children}</>;
}